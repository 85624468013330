.listingCard {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.info {
  display: flex;
  flex: 1;
  align-items: center;
}

.aspectRatioWrapper {
  width: 120px; /* Adjust based on your needs */
  height: 120px;
  margin-right: 20px;
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.mainInfo {
  flex: 1;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.authorInfo {
  font-size: 14px;
  color: #666;
}

.pfpSection {
  margin-left: 20px;
}

.pfp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.priceContainer {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.perUnit {
  margin-left: 5px; /* Adjust the space as needed */
}
/* Adjust PriceMaybe component if needed */
.price {
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 8px;
  flex-direction: row; /* Prevents line break */
}

/* For long words in the title */
.longWord {
  word-break: break-word;
}

.initialsPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: linear-gradient(135deg, #d7fb3c, #b6e01d);
  background-color: #d7fb3c;
  border-radius: 10px;
}

.initials {
  color: #ffffff;
  font-size: 42px;
  text-transform: uppercase;
}
