.backgroundLight {
  stop-color: var(--colorBannedLight);
}

.backgroundDark {
  stop-color: var(--colorBannedDark);
}

.foregroundFill {
  fill: var(--colorPhenomBlack);
}

.foregroundStroke {
  stroke: var(--colorPhenomBlack);
}
